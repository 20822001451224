import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

import Explanation from './content-types/Explanation';
import Image from './content-types/Image';

// Background images
import modal_bg_0 from '../../img/modal_bg_0.jpg';
import modal_bg_1 from '../../img/modal_bg_1.jpg';
import modal_bg_2 from '../../img/modal_bg_2.jpg';
import modal_bg_3 from '../../img/modal_bg_3.jpg';
import modal_bg_4 from '../../img/modal_bg_4.jpg';
import modal_bg_5 from '../../img/modal_bg_5.jpg';
import modal_bg_6 from '../../img/modal_bg_6.jpg';
import modal_bg_7 from '../../img/modal_bg_7.jpg';
import modal_bg_8 from '../../img/modal_bg_8.jpg';
import modal_bg_9 from '../../img/modal_bg_9.jpg';
import modal_bg_10 from '../../img/modal_bg_10.jpg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: '#cccccc',
    backgroundBlendMode: 'screen',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${getBackgroundImage()})`
  }
}));

const backgroundImages = [
  modal_bg_0, modal_bg_1, modal_bg_2, modal_bg_3, modal_bg_4,
  modal_bg_5, modal_bg_6, modal_bg_7, modal_bg_8, modal_bg_9, modal_bg_10
]

const getBackgroundImage = () => {
  return backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
}

const ExplanationModal = ({
  content,
  open,
  setOpen,
  solveTime,
  clockInterval,
  nextExercise,
  exerciseIds,
  exerciseId,
  exercise,
  history,
  setLoading,
  setnextExercise,
  setHasSecondColumn,
  setCurrentExercise,
  // enqueueSnackbar
}) => {
  const classes = useStyles();

  const onNext = () => {

    setOpen(false); // Close the modal

    // Determine the path to the next exercise, or go to the home page if no next exercise
    const path = nextExercise
      ? `/${nextExercise.type.toLowerCase()}/${nextExercise.id}`
      : '/';
    
    // Redirect to the next exercise or home
    history.push(path);
  };

  const onClickFinish = () => {
    clearInterval(clockInterval); // stop the clock
    setOpen(false);
    history.push('/');
  }

  const time_formatted = String(parseInt(solveTime / 60, 10)).padStart(2, '0') + ':' +
    String(solveTime - parseInt(solveTime / 60, 10) * 60).padStart(2, '0');

  const renderModalContent = (content) => {
    if (content) {
      return (
        <div>
          {
            content.map((content) => {
              switch (content.type) {
                case 'explanation':
                  return <Explanation content={content.payload} />;
                case 'explanation-image':
                  return <Image content={content.payload} />;
                default:
                  return <React.Fragment />;
              }
            })
          }
        </div>
      )
    }
  };


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{ maxWidth: "85%", minWidth: "30%" }}>
            <h2 id="transition-modal-title" align="center">
              Congratulations! You completed this exercise.
            </h2>
            <Typography align="center">
              {`You solved the exercise in ${time_formatted}`}
            </Typography>
            {renderModalContent(content)}
            <Grid container style={{ paddingTop: "20px" }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={onClickFinish}
                >
                  Finish
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onNext}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

ExplanationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  //clockInterval: PropTypes.number
};

ExplanationModal.defaultProps = {
  open: false,
  //clockInterval: undefined
};

export default ExplanationModal;
