import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut, soundOn, soundOff } from '../actions';
import { clearUserCookies } from './utils/utils';
import Logo from './utils/Logo';
import CustomButton from './utils/CustomButton';
import SaveIcon from '@material-ui/icons/Save';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: '800px',
  },
  container: {
    backgroundColor: '#F9E104',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  }, menuButton: {
    marginRight: theme.spacing(2)
  },
  link: {
    textDecoration: 'none',
    marginLeft: theme.spacing(2),
    height: '100%'
  },
  dropBoxLink: {
    color: '#000',
    textDecoration: 'none'
  },
  helpLink: {
    textDecoration: 'none',
    marginRight: theme.spacing(2)
  },
  exercise: {
    //padding: '3px 10px',
    border: 'solid 3px #AD6405',
    borderRadius: '10px',
    background: 'white',
    position: 'absolute',
    margin: 'auto',
    top: '5px',
    left: '40%',
    right: '40%',
    width: '200px',
    bottom: '5px',
    textAlign: 'center',
    height: '50px',
    lineHeight: '50px',
    color: '#000',
  },
  helpButton: {
    height: '34px',
    width: '34px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 2.5px #FFF',
    borderRadius: '50%',
    background: '#F99D07',
    boxShadow: '0 0 0 2px #AD6405, 0 2px 0 3px #AD6405',
    lineHeight: 1,
    margin: '0',
    color: '#fff',
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const { authed, exercise, exerciseIds, history, isSoundOn, solveTime, workspace } = props;
  const { pathname } = props.location;
  const [isHover, setIsHover] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [nextExercise, setnextExercise] = useState();
  const [headerName, setHeaderName] = useState();
  const location = useLocation();

  const handleOnClick = () => {
    clearUserCookies();
    props.signOut();
  };

  const handleHover = (event) => {
    setIsHover(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsHover(false);
    setAnchorEl(null);
  };

  // Can refactor with skip button in BlocklyMaze?
  const skip = () => {
    const path = nextExercise
      ? `/${nextExercise.type}/${nextExercise.id}`
      : '/';
    console.log('path', path);
    history.push(path);
  }

  useEffect(() => {
    if (exercise && exerciseIds && exerciseIds.length > 0) {
      // find the current exercise index in exercise id list in redux store
      const currentIndex = exerciseIds.findIndex(
        (elem) => elem.id === exercise._id
      );

      // if found current exercise and index is within bounds
      // set next exercise by incrementing the current index, otherwise set to 0
      if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
        setnextExercise(
          {
            id: exerciseIds[currentIndex + 1].id,
            type: exerciseIds[currentIndex + 1].type
          }
        );
      } else if (currentIndex == exerciseIds.length - 1) {
        setnextExercise(null);
      } else {
        // exerciseIds.length > 0
        setnextExercise(
          {
            id: exerciseIds[0].id,
            type: exerciseIds[0].type
          }
        );
      }
    } else {
      setnextExercise(null);
    }
  }, [exercise, exerciseIds]);

  const soundHandler = () => {
    if (isSoundOn) props.soundOff();
    else props.soundOn();
  }

  useEffect(() => {
    const url = location.pathname
    if (url?.includes("lesson")) {
      setHeaderName("Lesson")
    } else {
      setHeaderName("Exercise")
    }
  }, [location])

  //if (workspace) console.log('workspace', Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace)));

  // const saveWorkspace = () => {
  //   const performanceData = {
  //     Actual_lines: 'a',
  //     ExerciseCode:
  //       exercise.level +
  //       String(exercise.difficulty) +
  //       String(exercise.story).padStart(3, '0'),
  //     Expected_lines: 'a',
  //     Exp_time: exercise.expectedTime,
  //     Act_time: solveTime,
  //     Code: workspace,
  //     Status: 'Incomplete'
  //   };

  //   console.log(String(
  //     Blockly.Xml.domToPrettyText(Blockly.Xml.workspaceToDom(workspace, true))
  //   ));

  //   props.setAttempt({
  //     previous_code: performanceData.Code,
  //     previous_line: 0, // unused
  //     previous_time: performanceData.Act_time
  //   });

  //   axios
  //     .post(`${process.env.REACT_APP_LMS_API}/save`, performanceData, {
  //       headers: { Authorization: `Token ${getUserCookies()}` }
  //     })
  //     .then((response) => {
  //       if (response.data.success === 'True') {
  //         props.enqueueSnackbar('Code Saved', {
  //           variant: 'success'
  //         });
  //       } else {
  //         props.enqueueSnackbar('Failed to save code', {
  //           variant: 'error'
  //         });
  //       }
  //     });
  // };

  // if (exercise) console.log(exercise);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.container} >
          <Link to="/" className={classes.link}>
            <Logo />
          </Link>


          {!authed &&
            <Link to="/" className={classes.helpLink}>
              <Typography variant="h6">
                Help
              </Typography>
            </Link>
          }

          {authed && (
            <>
              {pathname !== '/' && exercise &&
                <Typography className={classes.exercise} variant="h6" color="inherit">
                  {headerName} {`${exercise._id}`}
                </Typography>
              }
              <div>
                <Button>
                  <div className={classes.helpButton} onClick={handleHover}>?</div>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={isHover}
                  onClick={handleClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Link to="/" className={classes.dropBoxLink}>
                    <MenuItem>FAQ</MenuItem>
                  </Link>
                  <MenuItem>Submit Request</MenuItem>
                  {pathname !== '/' && <MenuItem onClick={skip}>Skip Current Exercise</MenuItem>}
                  {pathname !== '/' && <MenuItem onClick={soundHandler}>Sound</MenuItem>}
                </Menu>
                {pathname !== '/' && <CustomButton content='Save' icon={<SaveIcon />} />}
                <CustomButton
                  onClick={handleOnClick}
                  content='Logout'
                  icon={<CloseIcon />}
                />
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authed: state.auth.isSignedIn,
  exercise: state.exercises.current,
  exerciseIds: state.exercises.ids,
  isSoundOn: state.exercises.isSoundOn,
  solveTime: state.exercises.solveTime,
  workspace: state.exercises.workspace,
});

NavBar.propTypes = {
  isSoundOn: PropTypes.bool.isRequired,
  solveTime: PropTypes.number.isRequired,
  authed: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  soundOn: PropTypes.func.isRequired,
  soundOff: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, { soundOn, soundOff, signOut })(NavBar));
