/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Timer from './Timer';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Box, Collapse } from '@material-ui/core';
import { setCurrentExercise, completeExercise, setAttempt, setSolveTime } from '../../actions';
import LoadingPage from './LoadingPage';
import ContentDisplay from './content-types/ContentDisplay';
import SolutionInput from './solution-input-types/SolutionInput';
import ExplanationModal from './ExplanationModal';
import BackSubmitNextButtons from './BackSubmitNextButtons';
import exerciseData from './temp-files/temp-exercise.json'; //to test ui using json format
import CodeArea from './content-types/CodeArea';
import { CodeButtons } from './CodeButtons';
import { HEADER_FONT_SIZE, ExerciseHeader, GeneralText } from '../common/CommonStyles';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "850px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    height: "850px"
  },
  question: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%'
    },
    // color: theme.palette.text.secondary
  },
  codeAnswer: {
    padding: theme.spacing(3),
    height: "fit-content",
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      height: '644px',
    },
    flexDirection: "column",
    display: "flex"
  },
  container: {
    display: "flex",
    justifyContent: "center"
  },
}));

const Exercise = (props) => {
  var {
    match: {
      params: { id: exerciseId }
    },
    exercise, // exercise data
    exerciseIds, // exercise id list used for link to next exercise
    history, // Routing variable
    // enqueueSnackbar
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [hasSecondColumn, setHasSecondColumn] = useState(false);
  const [nextExercise, setnextExercise] = useState();
  const [submissionList, setSubmissionList] = useState([]);
  const [solveTime, setSolveTime] = useState(0);
  const [stdout, setStdout] = useState("");
  const [stderr, setStderr] = useState();
  const [status, setStatus] = useState('Waiting for Submission');
  const [completed, setCompleted] = useState(false);
  const [activeSubquestion, setActiveSubquestion] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [isCodeRunning, setIsCodeRunning] = useState(false);
  const [clockInterval, setClockInterval] = useState();

  useEffect(() => {
    // Start the timer if the exercise is not completed
    const interval = setInterval(() => {
      if (!completed) {
        setSolveTime(prevCount => prevCount + 1);
      }
    }, 1000);

    // Clear the timer to prevent memory leaks
    return () => clearInterval(interval);
  }, [completed, nextExercise]); // Re-run when completed or nextExercise changes

  useEffect(() => {
    // Reset the timer when the nextExercise changes
    setSolveTime(0); // Reset the timer when switching exercises
  }, [nextExercise]);

  const onSubmission = (submission, index) => {
    const isStringArray = (submission) => {
      if (Array.isArray(submission)) {
        // Check if every element in the array is a string
        return submission.every(element => typeof element === 'string');
      }
      return false;
    };

    // check if no answer is submitted
    if (submission.length === 0) {
      setSubmissionList(submission)
    }
    else if (isStringArray(submission)) {
      // update submission for question at index 
      let newSubmissionList = [...submissionList];
      newSubmissionList[index] = submission;
      setSubmissionList(newSubmissionList);
    } else {
      console.warn("WARNING: Submission must be a string array!");
    }
  };

  const submit = async (isSubmit) => {
    try {
      if (submissionList.length === 0) {
        setIsCodeRunning(false);
        setStatus('No answer has been submitted');
        return;
      }

      setStatus('Grading the answer');
      const activeQuestionType = exercise.questions[activeSubquestion].type;
      const currentExercise = activeSubquestion == 0 ? exerciseId : exerciseId + "-" + activeSubquestion

      if (['true-false', 'multi-choice', 'text-boxes', 'drag-drop-text'].includes(activeQuestionType)) {
        const response = await axios.post(`
        ${process.env.REACT_APP_EXE_API}/grading/${currentExercise}`,
          { answer: submissionList[activeSubquestion] }
        );

        if (response?.data?.status === 'Accepted') {
          console.log('Correct!');
          setStatus('Correct Answer');
          setCompleted(true);
          setModalOpen(true);
          // set next question
          setActiveSubquestion(activeSubquestion + 1);
        } else {
          console.log('Incorrect!');
          setStatus('Wrong Answer');
        }
      } else if (activeQuestionType === 'code-input' || activeQuestionType === "code-input-only") {
        const submittedCode = submissionList[activeSubquestion][0];

        const formatCode = _.flow(
          (code) => { return Buffer.from(code).toString('base64') },
          (code) => { return { answer: [code] } }
        )

        const formattedCode = formatCode(submittedCode);
        const currentExercise = activeSubquestion == 0 ? exerciseId : exerciseId + "-" + activeSubquestion

        const submissionResponse = await axios.post(
          `${process.env.REACT_APP_EXE_API}/grading/${currentExercise}`, formattedCode)
        if (submissionResponse) {
          setIsCodeRunning(false)
          if (submissionResponse?.data?.status === 'Wrong Answer') {
            setStatus(submissionResponse?.data?.message ? submissionResponse.data.message : "Incorrect Answer")
            const action = snackbarId => (
              <button class='MuiButton-root MuiButton-text MuiButton-textError MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root  css-adtyxp' onClick={() => { props.closeSnackbar(snackbarId) }}>
                Dismiss
              </button>
            );

            props.enqueueSnackbar(submissionResponse.data.message, {
              variant: 'warning',
              autoHideDuration: 15000,
              action
            });
          }
          else if (submissionResponse?.data?.status === 'Accepted') {
            const token = submissionResponse.data.token;
            const checkStatus = async () => {
              const codeResultResponse = await axios.get(`${process.env.REACT_APP_EXE_API}/submission/python/${token}`)

              if (codeResultResponse) {
                setIsCodeRunning(false)
                if (codeResultResponse?.data?.status?.description === 'Accepted') {
                  if (isSubmit) {
                    setCompleted(true);
                    setModalOpen(true);
                  }
                  console.log("Answer is accepted")
                  setStatus('Correct Answer.');
                  setStdout(Buffer.from(codeResultResponse.data.stdout, 'base64').toString());
                } else if (codeResultResponse.data.status.description === 'Wrong Answer') {
                  console.log('WRONG_ANSWER');
                  setStatus('Wrong Answer.');
                  setStdout(Buffer.from(codeResultResponse.data.stdout, 'base64').toString());
                  if (codeResultResponse.data.stderr) {
                    setStderr(Buffer.from(codeResultResponse.data.stderr, 'base64').toString());
                  }
                } else if (codeResultResponse.data.status.description === 'Runtime Error (NZEC)') {
                  console.log('RUNTIME_ERROR');
                  setStatus('Wrong Answer. Check your code for mistakes.');
                  setStderr(Buffer.from(codeResultResponse.data.stderr, 'base64').toString());
                } else if (codeResultResponse.data.status.description === 'Compilation Error') {
                  console.log('COMPILE_ERROR');
                  setStatus('Wrong Answer. Check your code for mistakes.');
                  setStderr(Buffer.from(codeResultResponse.data.stderr, 'base64').toString());
                } else if (codeResultResponse.data.status.description === 'Processing') {
                  console.log('PROCESSING');
                  setStatus('Processing Code.');
                  setTimeout(checkStatus, 1000);
                } else if (codeResultResponse.data.status.description === 'In Queue') {
                  console.log('In Queue');
                  setStatus('Grading is in progress. Please wait.');
                  setTimeout(checkStatus, 1000);
                } else {
                  console.log('FAILED');
                  setStatus('Grading has failed.');
                }
              }
            }
            checkStatus();
          }
        }
      }
    } catch (error) {
      console.error('Error occurred during grading:', error);
      setStatus('Error occurred during  grading.');
      setIsCodeRunning(false)

      if (error?.response) {
        console.log(error.response);
      } else if (error?.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
    }
  }

  const handleCodeCallback = () => {
    setIsCodeRunning(true);
    submit(false)
  };

  const back = () => {
    if (activeSubquestion !== 0) {
      // Go back to the previous subquestion
      setActiveSubquestion(activeSubquestion - 1);
    } else {
      // At the first subquestion, navigate to the previous exercise or lesson
      if (exercise && exerciseIds && exerciseIds.length > 0) {
        const targetId = exercise._id;
        const targetType = exercise.language.toLowerCase();
        // Find the index of the target item
        const currentIndex = exerciseIds.findIndex(
          (elem) => elem.id === targetId && elem.type === targetType
        );
        if (currentIndex > 0) {
          // If not the first exercise, go to the previous one
          const prevExercise = exerciseIds[currentIndex - 1];
          const type = prevExercise.type === 'python' ? 'exercise' : (prevExercise.type === 'lesson' ? 'lesson' : '');
          
          setnextExercise({
            id: prevExercise.id,
            type: type
          });

          // Navigate to the previous exercise or lesson
          const path = `/${type}/${prevExercise.id}`;
          history.push(path);
        } else {
          // If at the first exercise, go to the home page
          history.push('/');
        }
      } else {
        // If no exercises available, go to the home page
        history.push('/');
      }
    }
  };

  const next = () => {
    // setModalOpen(false);
    // if (activeSubquestion != exercise.questionNumber - 1) {
    //   // set active question to the next sub question if there are other subquestions
    //   setActiveSubquestion(activeSubquestion + 1);
    // } else {
    //   // otherwise skip to next question at subquestion 0
    //   console.log('Next question');
    // }
    const path = nextExercise
    ? `/${nextExercise.type.toLowerCase()}/${nextExercise.id}`
    : '/';
    history.push(path);
  }

  // Get exercise data from database
  useEffect(() => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_EXE_API}/exercise/${exerciseId}`)
      .then((response) => {
        props.setCurrentExercise(response.data);
        setLoading(false);
      })
      .catch(() => {
        props.enqueueSnackbar('Failed to fetch exercise', {
          variant: 'error'
        });
        history.push('/');
      });
  }, [exerciseId]);

useEffect(() => {
    if (exercise) {
      if (exercise.hasSecondColumn) {
        setHasSecondColumn(true);
      }
    }
  if (exercise && exerciseIds && exerciseIds.length > 0) {
    // find the current exercise index in exercise id list in redux store
    const currentIndex = exerciseIds.findIndex(
      (elem) => elem.id === exercise._id
    );

    // if found current exercise and index is within bounds
    // set next exercise by incrementing the current index, otherwise set to 0
    if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
      const targetId = exercise._id;
      const targetType = 'python';

      // Find the index of the target item
      const currentIndex = exerciseIds.findIndex(
        (elem) => elem.id === targetId && elem.type === targetType
      );

      // If the current item is found and it's not the last item in the array
      if (currentIndex !== -1 && currentIndex < exerciseIds.length - 1) {
        // Get the next item in the array
        const nextExercise = exerciseIds[currentIndex + 1];
        const type = nextExercise.type === 'python' ? 'exercise' : (nextExercise.type === 'lesson' ? 'lesson' : '');
        // Set the next exercise
        setnextExercise({
          id: nextExercise.id,
          type: type
        });
      } else {
        // Handle the case where no match is found or it's the last item
        console.log('No next exercise found');
        setnextExercise(null);
      }
    } else if (currentIndex === exerciseIds.length - 1) {
      setnextExercise(null);
    } else {
      // exerciseIds.length > 0
      setnextExercise(
        {
          id: exerciseIds[0].id,
          type: exerciseIds[0].type
        }
      );
    }
  } else {
    setnextExercise(null);
  }
}, [exercise, exerciseIds]);

  useEffect(() => {
    if (exercise) {
      if (exercise.hasSecondColumn) {
        setHasSecondColumn(true);
      } else {
        setHasSecondColumn(false);
      }

      // Reinitialize or reset states that depend on exercise data
      clearInterval(clockInterval); // Stop the clock
      setSubmissionList([]); // Clear previous submissions
      setActiveSubquestion(0); // Reset to the first question
      setStdout(""); // Clear previous output
      setStderr(null); // Clear any previous errors
      setStatus('Waiting for Submission'); // Reset status
      setCompleted(false); // Mark as not completed
    }
  }, [exercise]);

  if (loading) return <LoadingPage />;
  return (
    <div className={classes.root}>
      {/* <GradingLoader
        open={grading}
      /> */}

      {/* Clock */}
      <Box display="flex" m={1} p={1} bgcolor="background.paper">
        <Grid item xs={12} >
          <Timer time={solveTime} />
        </Grid>
        <br />
      </Box>

      {/* Annotation and Instruction */}
      {exercise.questions.map((question, index) => {
        return (
          <Collapse in={index === activeSubquestion}>
            <Box display="flex" m={1} p={1} bgcolor="background.paper">
              <Grid container spacing={2} className={classes.container}>
                <Grid item md={question.hasSecondColumn ? 6 : 6} xs={12} style={{ height: question.hasSecondColumn && "708px", width: "50%", alignItems: "baseline", justifyContent: "center", display: "flex" }} >
                  <Paper className={classes.question} style={{ maxHeight: question.hasSecondColumn ? "644px" : "100%", overflowY: question.hasSecondColumn && "auto" }} >
                    <Typography as="h1" style={{ fontWeight: "bold", textAlign: 'center', fontSize: HEADER_FONT_SIZE }}>
                      {exercise.topic}
                    </Typography>
                    <br />
                    <ExerciseHeader style={{ fontWeight: "bold" }}>
                      Exercise {exercise._id}
                    </ExerciseHeader>
                    <br />
                    <ContentDisplay content={question.content} stdout={stdout} status={status} />
                    {question.hasSecondColumn === false &&
                      <div>
                        <SolutionInput index={index} language={exercise.language} question={question} onSubmission={onSubmission} isSecondColumn={false} />
                        {question.type === "code-input" &&
                          <CodeButtons codeCallback={handleCodeCallback} isCodeRunning={isCodeRunning} />
                        }
                        {question.type === "code-input" && (
                          <React.Fragment>
                            <br />
                            <CodeArea title={"Code Output:"} code={stdout} mode={"plain_text"} isEmpty={true} minLines={5} maxLines={8} placeholderText={"Your output will be displayed here"} showLineNumbers={true}/>
                          </React.Fragment>
                        )}
                        <GeneralText style={{ marginTop: "10px" }}>Status: {status}</GeneralText>
                      </div>
                    }
                  </Paper>
                </Grid>
                {
                  (question.hasSecondColumn === true) &&
                  <React.Fragment>
                    <Grid item md={6} xs={12} style={{ width: "50%", alignItems: "center", justifyContent: "center", display: "flex" }} >
                      <Paper className={classes.codeAnswer}>
                        <SolutionInput index={index} language={exercise.language} question={question} onSubmission={onSubmission} isSecondColumn={true} />
                        {question.type === "code-input" &&
                          <CodeButtons codeCallback={handleCodeCallback} isCodeRunning={isCodeRunning} />
                        }
                        <br />
                        {question.type == "code-input" && (
                          <CodeArea title={"Code Output:"} code={stdout} mode={"plain_text"} isEmpty={true} minLines={8} maxLines={8} placeholderText={"Your output will be displayed here"} showLineNumbers={true}/>
                        )}
                        <GeneralText style={{ marginTop: "5px" }}>Status: {status}</GeneralText>
                      </Paper>
                    </Grid>
                  </React.Fragment>
                }
                <Grid item xs={12} style={{ padding: "30px 0px" }}><BackSubmitNextButtons onBack={back} onSubmit={() => submit(true)} onNext={next} /></Grid>
                {/* Modal with explanation for exercise after completion */}
                <ExplanationModal
                  content={question.content} // To display the explanation part in content
                  // open={modalOpen}
                  // setOpen={setModalOpen}
                  onNext={() => { setModalOpen(false); }}
                  // solveTime={solveTime}
                  nextExercise={nextExercise}

                  open={completed}
                  setOpen={setCompleted}
                  history={history}
                  clockInterval={clockInterval}
                  solveTime={solveTime}
                  exerciseIds={exerciseIds}
                  exerciseId={exerciseId}
                  exercise={exercise}
                  setLoading={setLoading}
                  setnextExercise={setnextExercise}
                  setHasSecondColumn={setHasSecondColumn}
                  setCurrentExercise={setCurrentExercise}
                  // enqueueSnackbar={enqueueSnackbar}
                />
              </Grid>
            </Box>
          </Collapse>
        );
      })}
    </div>
  );
};

Exercise.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setCurrentExercise: PropTypes.func.isRequired,
  exercise: PropTypes.object,
  completeExercise: PropTypes.func.isRequired,
  setAttempt: PropTypes.func.isRequired,
  exerciseIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  )
};

Exercise.defaultProps = {
  exercise: null,
  exerciseIds: null
};

const mapStateToProps = (state) => ({
  exercise: state.exercises.current,
  exerciseIds: state.exercises.ids
});

export default connect(mapStateToProps, {
  setCurrentExercise,
  completeExercise,
  setAttempt
})(withSnackbar(Exercise));